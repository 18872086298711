<template>
  <main class="portfolio">
    <div class="portfolio__layout">
      <div class="portfolio__back">
        <button class="portfolio__back_btn" @click="$router.go(-1)">
          <ArrowIcon/>
        </button>
      </div>

      <section class="portfolio__main">
        <header class="portfolio__header">
          <div class="portfolio__titles">
            <h2 class="portfolio__title">{{ portfolio.title }}</h2>
            <span class="portfolio__description">{{ portfolio.description }}</span>
          </div>

          <div class="portfolio__info">
            <p class="portfolio__full-description">{{ portfolio.fullDescription }}</p>
            <div class="portfolio__tags">
              <span class="portfolio__tag" v-for="(tag, index) in portfolio.tags" :key="index">{{(index + 1) + '. ' + tag }}</span>
            </div>
          </div>
        </header>

        <section class="portfolio__images">
          <section class="portfolio__image" v-for="(image, index) in portfolio.contentImages" :key="index">
            <img class="portfolio__image-file" :src="contentImageURL(image.url)" :alt="image.description">
            <span class="portfolio__image-description">{{ image.description }}</span>
          </section>
        </section>


        <section class="portfolio__footer">
          <img v-if="portfolio.logoUrl" class="portfolio__logo" :src="imageLogoSrc" :alt="'Логотип проекта ' + portfolio.title">
          <time class="portfolio__year">{{ portfolio.year }}</time>
        </section>
      </section>
    </div>
  </main>
</template>

<script>
import ArrowIcon from '../components/Icons/ArrowIcon.vue'

export default {
  components: { ArrowIcon },

  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },

  data() {
    return {
      portfolios: [
        {
          id: 1,
          title: 'PODO',
          description: 'приложение для знакомств',
          fullDescription: 'Podo - это приложение для знакомств людей через совместный досуг. Любой человек может найти себе попутчика или партнера, создав тематическое событие в приложении.',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Продвижение', 'Поддержка'],
          contentImages: [
            {
              url: 'podo/podo_content.png',
              description: ''
            },
          ],
          logoUrl: 'podo/podo_logo.svg',
          year: '2021'
        },
        {
          id: 2,
          title: 'SPICY DROP',
          description: 'кейсы CS GO',
          fullDescription: 'Web-приложение для прокрутки кейсов. Связь со Steam, платежным сервисом, личный кабинет и система бонусов.',
          tags: ['Дизайн', 'Разработка'],
          contentImages: [
            {
              url: 'spicyDrop/spicyDrop_content.png',
              description: ''
            },
          ],
          logoUrl: 'spicyDrop/spicyDrop_logo.svg',
          year: '2020'
        },
        {
          id: 3,
          title: 'ГОРОД ГЕРОЕВ',
          description: 'система геймификация',
          fullDescription: 'Это площадка, где пользователь может отслеживать мероприятия и грантовые конкурсы, принимать в них участие и получать в награду внутреннюю валюту, которую можно обменять на различные призы в магазине площадки',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка'],
          contentImages: [
            {
              url: 'heroCity/heroCity_content.png',
              description: ''
            },
          ],
          logoUrl: 'heroCity/heroCity_logo.svg',
          year: '2020'
        },
        {
          id: 4,
          title: 'QBIK',
          description: 'конструктор журналов',
          fullDescription: 'Платформа для создания публикаций с помощью шаблонов. Просто загружая изображения и редактируя текст вы получаете готовый журнал или коллаж.',
          tags: ['Дизайн', 'Разработка'],
          contentImages: [
            {
              url: 'qbik/qbik_content.png',
              description: ''
            },
          ],
          logoUrl: 'qbik/qbik_logo.svg',
          year: '2021'
        },
        {
          id: 5,
          title: 'RC.CASH',
          description: 'кэшбэк сервис',
          fullDescription: 'RC.CASH — веб-платформа для осуществления выгодных покупок, где пользователи могут совершать покупки в магазинах из каталога, получать вознаграждения и дополнительные скидки, а также  использовать сервисы Яндекса и получать баллы за покупки в городских заведениях офлайн.',
          tags: ['Дизайн', 'Разработка', 'Поддержка'],
          contentImages: [
            {
              url: 'rcCash/rcCash_content.png',
              description: ''
            },
          ],
          logoUrl: 'rcCash/rcCash_logo.svg',
          year: '2020 - н.в.'
        },
        {
          id: 6,
          title: 'ПО ПУТИ',
          description: 'поиск совместных городских поездок',
          fullDescription: 'По пути – это мобильное приложение для поиска совместных городских поездок. Удобный интерфейс позволяет водителям легко найти попутчиков за небольшую стоимость, а пассажирам добраться до точки назначения дешевле, чем на такси. ',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка'],
          contentImages: [
            {
              url: 'alongTheWay/alongTheWay_content_1.png',
              description: 'Водитель'
            },
            {
              url: 'alongTheWay/alongTheWay_content_2.png',
              description: 'Пассажир'
            },
          ],
          logoUrl: '',
          year: '2020'
        },
        {
          id: 7,
          title: 'VSH25',
          description: 'приложение для улучшения сна',
          fullDescription: 'VSH25 – курсы по улучшению состояния здоровья, плюс удобный мониторинг внутри мобильного приложения. ',
          tags: ['Дизайн', 'Разработка'],
          contentImages: [
            {
              url: 'vsh25/vsh25_content.png',
              description: ''
            },
          ],
          logoUrl: 'vsh25/vsh25_logo.svg',
          year: '2020'
        },
        {
          id: 8,
          title: 'TELEPORT',
          description: 'такси!',
          fullDescription: 'Информационная система, объединяющая все таксопарки в одном приложении, разработанная по технологии Progressive Web Applications..',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка'],
          contentImages: [
            {
              url: 'teleport/teleport_content.png',
              description: ''
            },
          ],
          logoUrl: 'teleport/teleport_logo.svg',
          year: '2020'
        },
        {
          id: 9,
          title: 'JEAN’S CITY',
          description: 'интернет-магазин',
          fullDescription: 'Интернет магазин, разработанный с использованием платформы собственного производства на основе технологий Laravel и Vue JS.',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка'],
          contentImages: [
            {
              url: 'jeansCity/jeansCity_content.png',
              description: ''
            },
          ],
          logoUrl: 'jeansCity/jeansCity_logo.svg',
          year: '2020'
        },
        {
          id: 10,
          title: 'RC.HOUSE',
          description: 'жилищно-накопительного кооператив',
          fullDescription: 'Платформа для жилищно-накопительного кооператива, предназначенная для удобного управления собственной заявки на квартиру и внесения всех необходимых платежей.',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка'],
          contentImages: [
            {
              url: 'rcHouse/rcHouse_content.png',
              description: ''
            },
          ],
          logoUrl: 'rcHouse/rcHouse_logo.svg',
          year: '2020 - н.в.'
        },
        {
          id: 11,
          title: 'SPIN IT',
          description: 'онлайн-курсы',
          fullDescription: 'Платформа для проведения онлайн-курсов с возможностью коммуникации с учениками и обмена файлами домашних работ. ',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Продвижение', 'Поддержка'],
          contentImages: [
            {
              url: 'spinIt/spinIt_content.png',
              description: ''
            },
          ],
          logoUrl: 'spinIt/spinIt_logo.svg',
          year: '2021'
        },
        {
          id: 12,
          title: 'PRIMARY',
          description: 'элитное такси',
          fullDescription: 'Мобильное приложение для заказа элитного такси, с собственной панелью для автопарков.',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Продвижение', 'Поддержка'],
          contentImages: [
            {
              url: 'primary/primary_content_1.png',
              description: 'Водитель'
            },
            {
              url: 'primary/primary_content_2.png',
              description: 'Пассажир'
            },
          ],
          logoUrl: 'primary/primary_logo.svg',
          year: '2021'
        },
        {
          id: 13,
          title: 'QT KASSA',
          description: 'онлайн-платформа для кинотеатра',
          fullDescription: 'Автоматизированная онлайн-платформа для кинотеатра с собственными фискальными аппаратами, системой генерации и выдачи билетов, бар и онлайн расписание. ',
          tags: ['Концепция', 'Дизайн', 'Разработка'],
          contentImages: [
            {
              url: 'qtCassa/qtCassa_content.png',
              description: ''
            },
          ],
          logoUrl: '',
          year: '2021'
        },
      ]
    }
  },


  computed: {
    portfolio() {
      return this.portfolios.find(portfolio => portfolio.id == this.id ) || {}
    },

    imageLogoSrc() {
      if (!this.portfolio.logoUrl) return
      return require(`@/assets/portfolio/${this.portfolio.logoUrl}`)
    },
  },

  methods: {
    contentImageURL(url) {
      return require(`@/assets/portfolio/${url}`)
    },
  }
}
</script>

<style scoped>
.portfolio {
  width: 81.875em;
  margin: 9.0625em auto var(--section-bottom-margin);
  box-sizing: border-box;
}
@media (max-width: 1367px) {
  .portfolio {
    width: 100%;
    padding: 0 65px;
  }
}
@media (max-width: 1025px) {
  .portfolio {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .portfolio {
    margin: 3.125em auto var(--section-bottom-margin);
  }
}
@media (max-width: 426px) {
  .portfolio {
    padding: 0 25px;
  }
}
@media (max-width: 321px) {
  .portfolio {
    padding: 0 15px;
  }
}

.portfolio__back {
  text-align: left;
}
@media (max-width: 965px) {
  .portfolio__back {
    grid-column: 2;
  }
  .portfolio__back_btn {
    float: left;
    margin-bottom: 1.875em;
  }
}
.portfolio__back_btn {
  border: none;
  background: transparent;
  cursor: pointer;
}
@media (max-width: 425px) {
  .portfolio__back_btn {
    margin-bottom: 1.5em;
  }
}

.portfolio__layout {
  display: grid;
  grid-template-columns: 1fr minmax(1px, 68em) 1fr;
}
@media (max-width: 1366px) {
  .portfolio__layout {
    grid-template-columns: 1fr minmax(1px, 60em) 1fr;
  }
}
@media (max-width: 1250px) {
  .portfolio__layout {
    grid-template-columns: 1fr minmax(1px, 55em) 1fr;
  }
}
@media (max-width: 1124px) {
  .portfolio__layout {
    grid-template-columns: 1fr minmax(1px, 46.25em) 1fr;
  }
}


.portfolio__main {
  grid-column: 2;
}

.portfolio__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 6em;
  justify-content: space-between;
}
@media (max-width: 960px) {
  .portfolio__header {
    flex-direction: column;
    margin-bottom: 3em;
  }
}

.portfolio__titles {
  text-align: left;
  margin-right: 16.3125em;
  min-width: fit-content;
  flex-grow: 2;
}
@media (max-width: 960px) {
  .portfolio__titles {
    flex-grow: 0;
    margin-bottom: 3em;
    width: 100%;
  }
}

.portfolio__title {
  margin-bottom: 0.33em;
}

.portfolio__description,
.portfolio__full-description,
.portfolio__tag,
.portfolio__image-description,
.portfolio__year {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 140%;
  color: var(--light-black);
  opacity: 0.7;
}

@media (max-width: 425px) {
  .portfolio__description,
  .portfolio__full-description,
  .portfolio__tag,
  .portfolio__image-description,
  .portfolio__year {
    font-size: 0.875rem;
  }
}

.portfolio__info {
  flex-basis: 40.1875em;
  flex-shrink: 6;
}

@media (max-width: 960px) {
  .portfolio__info {
    flex-basis: 0;
    flex-shrink: 0;
  }
}

.portfolio__full-description {
  margin-bottom: 1.78em;
}

.portfolio__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.44em 2em;
}
@media (max-width: 425px) {
    .portfolio__tags {
    gap: 0.57em 1.4em;
  }
}

.portfolio__tag {
  font-size: 1rem;
}
@media (max-width: 425px) {
  .portfolio__tag {
    font-size: 0.875rem;
  }
}

.portfolio__images {

}

.portfolio__image {
  text-align: left;
  margin-bottom: 6em;
}

.portfolio__image-file {
  width: 100%;
  display: block;
  margin-bottom: 1em;
}

.portfolio__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio__logo {
  max-width: 12.375em;
  margin-bottom: 0.75em;
}

.portfolio__year {
  color: var(--black);
}

</style>
